<template>
    <main class="main" v-bar>
        <div>
            <div class="container bio_title">
                <div class="pre_title">{{ $t('biography.subtitle') }}</div>
                <h2>{{ $t('biography.title') }}</h2>
            </div>
            <div class="section_greeting section_bio container">
                <div class="section_greeting_text">
                    <h3>{{ $t('biography.description.title') }}</h3>
                    <div class="bio_subtitle" v-html="$t('biography.description.subtitle')">- український бізнесмен в сферах медіа та шоу-бізнесу, громадський діяч.</div>
                    <span v-html="$t('biography.description.text')"></span>
                </div>
                <img class="section_greeting_img" src="/img/bio_img.png" alt="bagraev">
            </div>
            <div class="section_lpath container lg_bg">
                <h2>{{ $t('biography.flow.title') }}</h2>
                <div class="slider_wrap">
                    <div class="timeline">
                        <span v-for="n of 60" :key="n"></span>
                    </div>
                    <carousel class="lpath_slider"
                        :dots="false"
                        :nav="false"
                        :loop="false"
                        :mouseDrag="false"
                        :touchDrag="true"
                        :margin="10"
                        :responsive="{0:{items: 1}, 768:{items: 2}, 1024:{items: 3}}"
                        >
                        <template slot="prev"><i class="slider_arrow slider_arrow_prev mdi mdi-chevron-left"></i></template>
                        <div class="lpath_item" v-for="(item, index) in timeline" :key="index">
                            <div>
                                <div class="lpath_item_title">{{ $t(`biography.flow.points[${index}].title`) }}</div>
                                <div class="lpath_item_subtitle">{{ $t(`biography.flow.points[${index}].subtitle`) }}</div>
                            </div>
                            <div class="lpath_item_image"><img :src="item.img" :alt="$t(`biography.flow.points[${index}].title`)"></div>
                            <div class="lpath_item_date">{{ item.date }}</div>
                        </div>
                        <template slot="next"><i class="slider_arrow slider_arrow_next mdi mdi-chevron-right"></i></template>

                    </carousel>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import carousel from 'vue-owl-carousel'
import { TimelineMax } from 'gsap';
import { Trans } from '@/shared/utils/TranslateMiddleware';

export default {
    metaInfo() {
        return {
            title: `${this.$t('header.title')} - ${this.$t('menu.biography')}`,
        }
    },
    name: 'HomePageComponent',
    components: {
        carousel
    },
    data: () => ({
        tl: new TimelineMax()
    }),
    computed: {
        currentLanguage () {
            return Trans.currentLanguage;
        },
        timeline() {
            return [
                {
                    date: 1991,
                    img: '/img/bio/tavria_games_1998.jpg'
                },
                {
                    date: 1998,
                    img: '/img/bio/chi.jpg'
                },
                {
                    date: 2000,
                    img: '/img/bio/rada_telemovlenya.jpg'
                },
                {
                    date: 2001,
                    img: '/img/bio/rtm.jpg'
                },
                {
                    date: 2001,
                    img: '/img/bio/m1.jpg'
                },
                // {
                //     date: 2007,
                //     img: '/img/bio/petronik.jpg'
                // },
                {
                    date: 2007,
                    img: '/img/bio/m2.jpg'
                },
                {
                    date: 2018,
                    img: '/img/bio/ticketbox.jpg'
                },
                {
                    date: 2018,
                    img: '/img/bio/koreya.jpg'
                }
            ]
        }
    },
    watch: {
        currentLanguage() {
            setTimeout(() => {
                this.tl
                    .staggerTo(('.section_bio .section_greeting_text p'), 0.3, {opacity: 1, x: 0}, 0.25, '+=0.5')
                    .to(('.section_lpath'), 0.3, {opacity: 1, y: 0});
            }, 0);
        }
    },
    mounted: function () {
        this.tl
            .staggerTo(('.section_bio .section_greeting_text p'), 0.3, {opacity: 1, x: 0}, 0.25, '+=0.5')
            .to(('.section_bio .section_greeting_img'), 0.3, {opacity: 1, x: 0}, '-=1.75')
            .to(('.section_lpath'), 0.3, {opacity: 1, y: 0})
    }
}
</script>

<style lang="scss" scoped>
    .slider_arrow {
        cursor: pointer;
    }
</style>